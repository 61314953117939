<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div
        v-if="item"
        class="mt-2 pt-75"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.first_name.label')"
                label-for="first_name"
              >
                <b-form-input
                  id="first_name"
                  v-model="item.first_name"
                  :state="errors && errors.first_name ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.first_name">
                  {{ errors.first_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.last_name.label')"
                label-for="last_name"
              >
                <b-form-input
                  id="last_name"
                  v-model="item.last_name"
                  :state="errors && errors.last_name ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.last_name">
                  {{ errors.last_name[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.email.label')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="item.email"
                  :state="errors && errors.email ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.email">
                  {{ errors.email[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.phone.label')"
                label-for="phone"
              >
                <b-input-group>
                  <b-input-group-prepend
                    is-text
                    class="text-monospace"
                  >
                    +38
                  </b-input-group-prepend>
                  <b-form-input
                    id="phone"
                    v-model="item.phone"
                    :state="errors && errors.telegram ? false : null"
                  />
                </b-input-group>
                <b-form-invalid-feedback v-if="errors && errors.phone">
                  {{ errors.phone[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.telegram.label')"
                label-for="telegram"
              >
                <b-form-input
                  id="telegram"
                  v-model="item.telegram"
                  :state="errors && errors.telegram ? false : null"
                  placeholder="@mytelegram"
                />

                <b-form-invalid-feedback v-if="errors && errors.telegram">
                  {{ errors.telegram[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.role.label')"
                label-for="role"
                :state="errors && errors.role ? false : null"
              >
                <b-form-select
                  v-model="item.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  input-id="role"
                />

                <b-form-invalid-feedback v-if="errors && errors.role">
                  {{ errors.role[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.notify_general.label')"
                label-for="notify_general"
              >
                <b-form-checkbox
                  id="notify_general"
                  v-model="item.notify_general"
                  value="1"
                />
              </b-form-group>
              <b-form-group
                :label="$t('form.notify_promo.label')"
                label-for="notify_promo"
              >
                <b-form-checkbox
                  id="notify_promo"
                  v-model="item.notify_promo"
                  value="1"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.percent_cashback.label')"
                label-for="percent_cashback"
              >
                <b-form-input
                  id="percent_cashback"
                  v-model="item.percent_cashback"
                  type="number"
                  min="0"
                  max="99"
                  step="0.1"
                  :state="errors && errors.percent_cashback ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.percent_cashback">
                  {{ errors.percent_cashback[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('form.percent_referral.label')"
                label-for="percent_referral"
              >
                <b-form-input
                  id="percent_referral"
                  v-model="item.percent_referral"
                  min="0"
                  max="99"
                  step="0.1"
                  :state="errors && errors.percent_referral ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.percent_referral">
                  {{ errors.percent_referral[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('general.save') }}
          </b-button>
          <b-button

            type="button"
            variant="outline-secondary"
            :to="{ name: 'admin:users-index' }"
          >
            {{ $t('general.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'

import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import { required, alphaNum, email } from '@validations'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      required,
      alphaNum,
      email,
      roleOptions: [],
      item: null,
    }
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  async beforeCreate() {
    await this.$http.get('/api/roles').then(response => {
      const { data } = response.data

      this.roleOptions = this.$options.filters.transformForBSelect(data, 'name', 'title')
    })

    await this.$http.get(`/api/users/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = undefined
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'users-index' })
        }
      })
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    async onSubmit() {
      const data = {
        first_name: this.item.first_name,
        last_name: this.item.last_name,
        email: this.item.email,
        phone: this.item.phone,
        telegram: this.item.telegram,
        role: this.item.role,
        notify_general: this.item.notify_general,
        notify_promo: this.item.notify_promo,
        percent_cashback: this.item.percent_cashback,
        percent_referral: this.item.percent_referral,
      }

      this.$http.put(`/api/users/${this.item.id}`, data)
        .then(() => {
          router.replace({ name: 'admin:users-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData(item) {
      return {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        phone: item.phone,
        role: item.roles[0].name,
        telegram: item.telegram,
        notify_general: item.notify_general,
        notify_promo: item.notify_promo,
        percent_cashback: item.percent_cashback,
        percent_referral: item.percent_referral,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
